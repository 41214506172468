import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { REHYDRATE } from 'redux-persist';
import axiosPrivateInstance from "api/axiosPrivate";
import  { setCredentials, logout } from "context/auth/authSlice";

const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_API_URL; 

const baseQuery = fetchBaseQuery({
    baseUrl : API_URL,
    credentials : 'include',
    prepareHeaders : (headers, {getState}) =>{
        const token = getState().auth.accessToken;
        if(token){
            headers.set("Authorization",`Bearer ${token}`);
        }
        return headers
    }
});

export const axiosBaseQuery = async ({ url, method, data, params, config }) => {
  const { axiosPrivate, removeAxiosPrivateIntercept } = axiosPrivateInstance();
	try {
		const result = await axiosPrivate({ url, method, data, params, ...config });
		removeAxiosPrivateIntercept();
		return { data: result.data }
	} catch (axiosError) {
		return {
			error: {
				status: axiosError.response?.status,
				data: axiosError.response?.data || axiosError.message,
			},
		}
	}
}
const baseQueryWithReAuth = async (args,api,extraOptions)=>{
    let result = await baseQuery(args,api,extraOptions);
    if( result?.error?.originalStatus === 403 || result?.error?.originalStatus === 401 || result?.error?.status === 401 ){
        // sending the refresh token to get access authorizations 
        const refreshResult = await baseQuery('/refresh',api, extraOptions);
        if(refreshResult?.data){
            api.dispatch(setCredentials({...refreshResult.data}));
            // retry the original query with new token
            result = await baseQuery (args,api,extraOptions);
        }else{
            /*eslint-disable */
            if( 0 === 1 ) api.dispatch(logout());
        }
    }
    return result;
}
 /* eslint-disable */
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery : baseQueryWithReAuth,
    extractRehydrationInfo(action, { reducerPath }) {
        if (action.type === REHYDRATE) {
          return action.payload ? action.payload[reducerPath] : undefined
        }
    },
    tagTypes: ['User', 'Page',  'Config', 'Chart', 'Review','Icon','Product', 'Province', 'JobsList','Category','Distributer','DistributerCategory'],
     /* eslint-disable */
    endpoints : builder =>({})
})
